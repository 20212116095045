import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { DragEndEvent } from '@dnd-kit/core/dist/types';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { TestResponsePrivate, TestValidationErrorTarget } from '@tests/types';
import React, { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MenuItem } from '@/atoms';
import { QuestionPreview, SortableItem } from '@/components';
import { questionsItemsSelector } from '@/selectors/questions';
import { useGetQuestionsListQuery, useUpdateQuestionsOrderMutation } from '@/services';
import { changeQuestionsOrder } from '@/slices';
import { getIds, getSortValuesFunc, getValidationErrorsByTarget, isCrossword } from '@/utils';

import { AddQuestionButton } from '../../components/AddQuestionButton';
import { validationErrorsTestsSelector } from '../../selectors';
import styles from './styles.module.scss';

type Props = {
  selectedQuestion: number;
  setActiveEditContent: React.Dispatch<SetStateAction<string | number>>;
  test: TestResponsePrivate;
};

export const QuestionsPreviewList: React.FC<Props> = ({
  selectedQuestion,
  setActiveEditContent,
  test,
}) => {
  const questionsListRef = useRef<HTMLDivElement>(null);
  const [isAddedQuestion, setIsAddedQuestion] = useState(false);
  const { id, type } = test;

  const dispatch = useDispatch();

  const [updateQuestionsOrder] = useUpdateQuestionsOrderMutation();

  useGetQuestionsListQuery(
    { limit: 100, offset: 0, relations: ['media', 'answers'], test: id },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  const questions = useSelector(questionsItemsSelector);
  const validationErrors = useSelector(validationErrorsTestsSelector);
  const hasErrorsQuestions =
    getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Question)?.length > 0;
  const hasErrorsAnswers =
    getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Answer)?.length > 0;

  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: { delay: 300, tolerance: 50 },
    }),
    useSensor(MouseSensor, {
      activationConstraint: { delay: 0, tolerance: 50 },
    }),
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const dragSort = getSortValuesFunc(questions);
      const sortChanges = dragSort(event);
      dispatch(changeQuestionsOrder([...sortChanges]));
      updateQuestionsOrder({ id, ids: getIds(sortChanges) });
    },
    [dispatch, id, questions, updateQuestionsOrder],
  );

  const handleAddQuestion = () => {
    setIsAddedQuestion(true);
  };

  const handleDeleteQuestion = (id: string, selected: boolean) => {
    if (selected) {
      const questionIndex = questions.findIndex((question) => question.id === +id);
      if (questionIndex !== -1) {
        setActiveEditContent(questions[questionIndex - 1].id);
      } else {
        setActiveEditContent(questions[0].id);
      }
    }
  };

  useEffect(() => {
    if (isAddedQuestion) {
      questionsListRef.current?.scrollTo({
        behavior: 'smooth',
        top: questionsListRef.current.scrollHeight,
      });
      setIsAddedQuestion(false);
      setActiveEditContent(questions[questions.length - 1].id);
    }
  }, [questions]);

  if (!questions) {
    return <div>Нет вопросов!</div>;
  }

  return (
    <>
      <MenuItem
        name="questions"
        label="Вопросы"
        disabled={false}
        hasErrors={hasErrorsQuestions || hasErrorsAnswers}
        className={styles.menuItem}
        questionsCount={questions.length}
      />
      <div className={styles.container}>
        <div className={styles.questionsPreviewList} ref={questionsListRef}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
            <SortableContext
              items={questions}
              strategy={rectSortingStrategy}
              disabled={isCrossword(type)}
            >
              {questions.map((question, index) => (
                <SortableItem id={question.id} key={question.id} childHandle>
                  <QuestionPreview
                    question={question}
                    index={index}
                    onDelete={handleDeleteQuestion}
                    onClick={setActiveEditContent}
                    selected={question.id === selectedQuestion}
                    canDelete={questions.length > 1}
                  />
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <AddQuestionButton test={test} onClick={handleAddQuestion} />
      </div>
    </>
  );
};
