import {
  AnswerResponsePrivate,
  AnswerType,
  getAnswerTextLengthByTestType,
  QuestionResponsePrivate,
  TestType,
} from '@tests/types';
import { Col, Form, Radio, Row } from 'antd';
import classNames from 'classnames';
import { inc, last, pipe, prop } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AddButton } from '@/atoms';
import { AnswerBlock } from '@/containers/Blocks/AnswersBlock/AnswerBlock';
import { useDebounce, useGetValidationError } from '@/hooks';
import {
  useCreateAnswerMutation,
  useGetAnswersListQuery,
  useGetResultsListQuery,
} from '@/services';
import { isCrossword, isExtendedTest, isImageAnswer, isQuiz, isTextAnswer } from '@/utils';

import { getAnswersMaxLength } from '../../../utils/answers';
import { ExtendedAnswerBlock } from './ExtendedAnswerBlock';
import styles from './styles.module.scss';

type Props = {
  question: QuestionResponsePrivate;
  testId: number;
  testType: TestType;
  updateBlock: any;
};

const isFullGrid = (testType: TestType) => isQuiz(testType) || isExtendedTest(testType);

export const AnswersBlock: React.FC<Props> = ({ question, testId, testType, updateBlock }) => {
  const { answerType, id } = question;

  const [createAnswer] = useCreateAnswerMutation();
  const answerMaxLength = getAnswerTextLengthByTestType(testType) || 100;

  const { data: { results: answers = [] } = {} } = useGetAnswersListQuery(
    { question: id, relations: ['media', 'resultMedia', 'results'] },
    { refetchOnMountOrArgChange: true, skip: !id },
  );

  const { data: { results } = {} } = useGetResultsListQuery(
    { relations: ['media'], test: testId },
    {
      refetchOnMountOrArgChange: true,
      skip: !testId,
    },
  );

  const resulstItems = useMemo(
    () =>
      results?.map((result, index) => ({
        label: result.title || `Результат №${index + 1}`,
        tag: (
          <span className={styles.tag} style={{ backgroundColor: result.color }}>
            Р{index + 1}
          </span>
        ),
        value: result.id,
      })),
    [results],
  );

  const correctAnswer = answers.find((answer) => answer.isCorrect);

  const [currentCorrectAnswer, setCurrentCorrectAnswer] = useState<AnswerResponsePrivate | null>(
    correctAnswer || null,
  );

  const [form] = Form.useForm();

  const { getFieldsValue, submit } = form;

  useEffect(() => {
    setCurrentCorrectAnswer(correctAnswer);
  }, [correctAnswer]);

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 1000);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    await updateBlock({
      ...formValues,
      id,
    });
  }, [getFieldsValue, updateBlock, id]);

  const handleValuesChangeHandler = useCallback(() => {
    handleFormChangeHandler.current();
  }, [handleFormChangeHandler]);

  const getLastAnswerSortOrder = pipe(last, prop('sortOrder'), inc);
  const questionAnswersError = useGetValidationError({ field: 'answers', targetId: id });

  const handleAddAnswer = useCallback(async () => {
    await createAnswer({ questionId: id, sortOrder: getLastAnswerSortOrder(answers) });
  }, [id, getLastAnswerSortOrder, createAnswer]);

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        initialValues={{ answerType }}
        onFinish={handleFinish}
        onValuesChange={handleValuesChangeHandler}
      >
        <Form.Item name="answerType">
          <Radio.Group className={styles.typeContainer}>
            <Radio.Button
              className={classNames(
                styles.answerType,
                isTextAnswer(answerType) && styles[testType],
              )}
              value={AnswerType.Text}
            >
              Текст
            </Radio.Button>
            {!isCrossword(testType) && (
              <Radio.Button
                className={classNames(
                  styles.answerType,
                  isImageAnswer(answerType) && styles[testType],
                )}
                value={AnswerType.Image}
              >
                Текст + Изображение
              </Radio.Button>
            )}
          </Radio.Group>
        </Form.Item>
      </Form>
      <Row gutter={[15, 15]}>
        {answers?.map((answer, index) => (
          <Col span={24} sm={isFullGrid(testType) ? 24 : 12}>
            {isExtendedTest(testType) ? (
              <ExtendedAnswerBlock
                key={answer.id}
                canDelete={answers.length > 2}
                answerType={answerType}
                testType={testType}
                answer={answer}
                currentCorrectAnswer={currentCorrectAnswer}
                questionAnswersError={questionAnswersError}
                index={index}
                results={resulstItems}
                maxLength={answerMaxLength}
              />
            ) : (
              <AnswerBlock
                key={answer.id}
                canDelete={answers.length > 2}
                answerType={answerType}
                testType={testType}
                answer={answer}
                testId={testId}
                currentCorrectAnswer={currentCorrectAnswer}
                questionAnswersError={questionAnswersError}
                index={index}
                maxLength={answerMaxLength}
              />
            )}
          </Col>
        ))}
        {answers?.length < getAnswersMaxLength(testType) && (
          <Col span={24} sm={isFullGrid(testType) ? 24 : 12}>
            <AddButton onClick={handleAddAnswer} />
          </Col>
        )}
      </Row>
    </div>
  );
};
