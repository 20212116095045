import { PlusOutlined } from '@ant-design/icons';
import {
  EmployeeResponsePrivate,
  PermissionAction,
  PermissionResource,
  RoleResponsePrivate,
} from '@tests/types';
import { Col, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import classNames from 'classnames';
import { omit } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Filters } from '@/assets';
import { Button } from '@/atoms';
import {
  EmployeeEditor,
  EmployeeFilters,
  EmployeeLists,
  RoleEditor,
  TestsListHeader,
} from '@/containers';
import { EmployeePasswordEditor } from '@/containers/EmployeePasswordEditor';
import { useFilterPage, usePermissions, useWindowDimensions } from '@/hooks';
import { useGetCurrentUserQuery, useGetEmployeesListQuery, useGetRolesListQuery } from '@/services';
import { ButtonType } from '@/types';

import { DrawerWrapper } from '../../components';
import { RolesList } from '../../containers/RolesList';
import styles from './styles.module.scss';

export const EmployeesPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Employee');
  const [showFilter, setShowFilter] = useState(false);
  const [employeeEditorData, setEmployeeEditorData] = useState(null);
  const [showEmployeeEditorModal, setShowEmployeeEditorModal] = useState(false);
  const [showEmployeePasswordEditorModal, setShowEmployeePasswordEditorModal] = useState(false);
  const [showRoleEditor, setShowRoleEditor] = useState(null);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [load, setLoad] = useState(false);

  const { filter, setFilter } = useFilterPage();

  useEffect(() => {
    if (!load) {
      setFilter({ offset: 0, page: 1 });
      setLoad(true);
    }
  }, [load]);

  const navigate = useNavigate();

  const checkPermissions = usePermissions;

  const pagePermitted = checkPermissions(PermissionResource.Employee, PermissionAction.Read);

  const handleShowEmployeeEditor = (employee: EmployeeResponsePrivate<string>) => {
    setEmployeeEditorData(employee);
    setShowEmployeeEditorModal(true);
  };

  const handleShowEmployeePasswordEditor = (employee: EmployeeResponsePrivate<string>) => {
    setEmployeeEditorData(employee);
    setShowEmployeePasswordEditorModal(true);
  };

  const handleShowRoleEditor = (role: RoleResponsePrivate<string>) => {
    setShowRoleEditor(role);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setFilter({ name: value });
  };

  const { data: employeesCountData, refetch: employeesRefetch } = useGetEmployeesListQuery(
    {
      ...omit(['page'], filter),
      order: '+name',
      relations: ['role', 'avatar', 'projects.logo'],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Employee, PermissionAction.Read),
    },
  );

  const { data: rolesCountData } = useGetRolesListQuery(
    {
      relations: ['permissions'],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Role, PermissionAction.Read),
    },
  );

  const { data: user } = useGetCurrentUserQuery();

  const windowWidth = useWindowDimensions().width;
  const mobileDimension = 768;
  const [isMobile, setIsMobile] = useState(windowWidth < mobileDimension);

  useEffect(() => {
    setIsMobile(windowWidth < mobileDimension);
  }, [windowWidth]);

  const tabs: TabsProps['items'] = [
    {
      key: 'Employee',
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Пользователи</Col>
          <Col className={styles.tabBadge}>{employeesCountData?.count}</Col>
        </Row>
      ),
    },
    {
      key: 'Role',
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Роли</Col>
          <Col className={styles.tabBadge}>{rolesCountData?.count}</Col>
        </Row>
      ),
    },
  ];

  const permittedTabs: TabsProps['items'] = tabs.filter((tab) =>
    checkPermissions(tab.key as PermissionResource, PermissionAction.Read),
  );

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const additionalTabButtons = {
    right: activeTab === 'Employee' && !isMobile && (
      <Space
        className={classNames(styles.showFilterBtn, showFilter && styles.activeShowFilterBtn)}
        onClick={handleShowFilter}
      >
        <Row align="middle">
          <Filters />
        </Row>
        <Row align="middle">
          {showFilter ? <Col span={24}>Скрыть фильтры</Col> : <Col span={24}>Все фильтры</Col>}
        </Row>
      </Space>
    ),
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <>
      <DefaultTabBar {...props} />
      {activeTab === 'Employee' && isMobile && (
        <Row
          gutter={[5, 0]}
          align="middle"
          className={classNames(styles.showFilterBtn, showFilter && styles.activeShowFilterBtn)}
          onClick={handleShowFilter}
        >
          <Col>
            <Row>
              <Filters />
            </Row>
          </Col>
          <Col>
            {showFilter ? <Col span={24}>Скрыть фильтры</Col> : <Col span={24}>Все фильтры</Col>}
          </Col>
        </Row>
      )}
      {showFilter && activeTab === 'Employee' && (
        <DrawerWrapper
          title="Все фильтры"
          open={showFilter}
          scrollActive
          handleClose={() => setShowFilter(false)}
          height="calc(100% - 60px)"
          mobileDimension={768}
        >
          <EmployeeFilters onMobileSubmit={() => setShowFilter(false)} mobileDimension={768} />
        </DrawerWrapper>
      )}
    </>
  );

  useEffect(() => {
    if (user && !pagePermitted) {
      navigate('/no-access');
    }
  }, [navigate, pagePermitted, user]);

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <TestsListHeader
            hideSearch={activeTab === 'Role'}
            onSearch={handleSearch}
            button={
              usePermissions(PermissionResource.Employee, PermissionAction.Create) && (
                <Button
                  type={ButtonType.Primary}
                  beforeIcon={<PlusOutlined />}
                  onClick={() => setShowEmployeeEditorModal(true)}
                >
                  <span className={styles.addEmployeeBtnText}>Добавить сотрудника</span>
                </Button>
              )
            }
          />
        </Col>
      </Row>
      <Col className={styles.settingsWrapper}>
        <Row>
          <Typography.Title level={4} style={{ fontSize: '21px' }}>
            Сотрудники
          </Typography.Title>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              className={styles.tabs}
              destroyInactiveTabPane
              onChange={(e) => setActiveTab(e)}
              tabBarExtraContent={additionalTabButtons}
              tabBarGutter={20}
              defaultActiveKey="users"
              items={permittedTabs}
              renderTabBar={renderTabBar}
            />
            {activeTab === 'Role' && (
              <RolesList isMobile={isMobile} onEditClick={handleShowRoleEditor} />
            )}
            {activeTab === 'Employee' && (
              <EmployeeLists
                isMobile={isMobile}
                handleShowEditor={handleShowEmployeeEditor}
                handleShowPasswordEditor={handleShowEmployeePasswordEditor}
              />
            )}
          </Col>
        </Row>
      </Col>
      {showEmployeeEditorModal && (
        <EmployeeEditor
          employee={employeeEditorData}
          onClose={() => {
            setShowEmployeeEditorModal(false);
            setEmployeeEditorData(null);
          }}
          employeesRefetch={employeesRefetch}
        />
      )}
      {showEmployeePasswordEditorModal && (
        <EmployeePasswordEditor
          employee={employeeEditorData}
          onClose={() => {
            setShowEmployeePasswordEditorModal(false);
            setEmployeeEditorData(null);
          }}
        />
      )}
      {showRoleEditor && (
        <RoleEditor
          role={showRoleEditor}
          onClose={() => setShowRoleEditor(null)}
          rolesRefetch={() => {}}
        />
      )}
    </>
  );
};
